import { translate, translateFormatter, type RichTextFormatter } from '@/core/localization/translate';
export const {
  leonidasHelloText,
  leonidasLoginText
} = translate({
  "leonidasHelloText": "Hello",
  "leonidasLoginText": "Sign in"
});
export const leonidasMobileLoginText = translateFormatter<{
  br: RichTextFormatter;
}>("Sign <br></br>in", undefined, undefined, "en");