import { translate } from '@/domains/core/localization/translate';
export const {
  individualLoginText,
  individualLoginDescription,
  proLoginText,
  proLoginDescription,
  proPreRegisterDescription,
  proPreRegisterUrl
} = translate({
  "individualLoginText": "I am an individual",
  "individualLoginDescription": "Access my account",
  "proLoginText": "I am a Professional",
  "proLoginDescription": "Get access to Pro prices",
  "proPreRegisterDescription": "Pre-register",
  "proPreRegisterUrl": "http://pro.manomano.com/de/"
});